import React from 'react';

const SvgMijnMkg = ({width = '32px', fill = '#111b28', classes = null}) => {
    return (
        <svg className={classes} version="1.1" id="Layer_3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 264 264" style={{enableBackground: 'new 0 0 264 264', width: width, fill: fill}}>
            <g>
                <g>
                    <path d="M98.4,40.3h-2.7V11.4l-10.6,24h-2.7l-10.8-24v28.9H69v-35h2.7l12.2,27l11.9-27h2.7V40.3z"/>
                    <path d="M111.4,8.4h-3.1V5.3h3.1V8.4z M111.1,40.3h-2.5V16.6h2.5V40.3z"/>
                    <path d="M123.2,45.5c0,1.9-0.5,3.4-1.5,4.5c-1,1.1-2.4,1.6-4.2,1.6h-2.2v-2.2h1.7c1.4,0,2.3-0.3,2.8-1c0.5-0.6,0.8-1.7,0.8-3V16.6
			h2.5V45.5z M123.5,8.4h-3.1V5.3h3.1V8.4z"/>
                    <path d="M151,40.3h-2.5V25.6c0-2.3-0.6-4-1.7-5.3c-1.1-1.2-2.7-1.8-4.9-1.8c-1.4,0-2.6,0.3-3.6,0.8c-1,0.6-1.8,1.4-2.3,2.4
			c-0.5,1.1-0.8,2.3-0.8,3.8v14.7h-2.5V16.6h2.5v2.8c1.8-2.1,4.1-3.1,7-3.1c2.7,0,4.8,0.8,6.3,2.3c1.6,1.6,2.4,3.7,2.4,6.6V40.3z"/>
                </g>
                <g>
                    <g>
                        <path d="M224.2,258.7H39.8c-14.4,0-26.1-11.7-26.1-26.1V45.7c0-14.4,11.7-26.1,26.1-26.1h11.4v7.5H39.8
				c-10.3,0-18.6,8.3-18.6,18.6v186.9c0,10.3,8.3,18.6,18.6,18.6h184.4c10.3,0,18.6-8.3,18.6-18.6V45.7c0-10.3-8.3-18.6-18.6-18.6
				h-53.7v-7.5h53.7c14.4,0,26.1,11.7,26.1,26.1v186.9C250.3,247,238.6,258.7,224.2,258.7z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path d="M53.1,171.9c-1,0-1.8-0.8-1.8-1.8v-55.4c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8v55.4
							C54.9,171.1,54.1,171.9,53.1,171.9z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M215.2,171.9c-1,0-1.8-0.8-1.8-1.8v-55.4c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8v55.4
							C217,171.1,216.2,171.9,215.2,171.9z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M161.2,171.9c-1,0-1.8-0.8-1.8-1.8v-55.4c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8v55.4
							C163,171.1,162.2,171.9,161.2,171.9z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M107.1,171.9c-1,0-1.8-0.8-1.8-1.8v-55.4c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8v55.4
							C109,171.1,108.1,171.9,107.1,171.9z"/>
                                </g>
                            </g>
                        </g>
                        <g>
                            <path d="M92.6,141.3v-16.7c0-3.8-2.4-5-4.6-5c-2.1,0-4.6,1.3-4.6,4.9v16.8h-6.9v-16.7c0-3.8-2.4-5-4.6-5c-2.2,0-4.6,1.3-4.6,5
					v16.7h-6.9v-27.6h6.7v2.5c1.8-1.9,4.3-2.9,6.9-2.9c3.1,0,5.6,1.1,7.3,3.5c2.3-2.4,4.8-3.5,8.3-3.5c2.7,0,5.2,0.9,6.8,2.4
					c2.2,2.2,3,4.8,3,7.8v17.6H92.6z"/>
                        </g>
                        <g>
                            <path
                                d="M140,141.3l-6.8-11.5l-2.9,3.3v8.2h-6.9v-37.7h6.9V125l9.2-11.3h8.3l-9.8,11.1l10.6,16.5H140z"/>
                        </g>
                        <g>
                            <path d="M187.9,153c-4.4,0-7.4-0.9-10.3-3.7l4.4-4.5c1.6,1.5,3.2,2.2,5.5,2.2c4.2,0,5.7-3,5.7-5.9v-2.9c-1.9,2.1-4,2.8-6.7,2.8
					c-2.8,0-5.2-0.9-6.8-2.5c-2.7-2.7-2.9-6.3-2.9-11.3c0-5,0.2-8.5,2.9-11.2c1.6-1.6,4-2.5,6.8-2.5c3,0,5,0.8,6.9,3V114h6.9v27.3
					C200.4,148.1,195.6,153,187.9,153z M188.6,120c-4.2,0-4.6,3.6-4.6,7.3c0,3.8,0.4,7.4,4.6,7.4c4.2,0,4.7-3.6,4.7-7.4
					C193.3,123.6,192.8,120,188.6,120z"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>);
}
export default SvgMijnMkg;