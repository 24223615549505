'use client'
import Image from "next/image";
import Link from "next/link";
import {useFormState, useFormStatus} from "react-dom";
import {useTekstClient} from "hooks/useTekstClient";
import {authenticate} from "../../lib/actions";
import Alert from "react-bootstrap/Alert";
import React, {useEffect, useState} from "react";
import {startsWith} from "lodash";
import {Input} from "@nextui-org/react";
import {EyeSlashFilledIcon} from "./EyeSlashFilledIcon";
import {EyeFilledIcon} from "./EyeFilledIcon";
import {useAxios} from "hooks/useAxios";
import {useRouter} from 'next/navigation'
import useGebruiker from "../../hooks/useGebruiker";
import {useSWRConfig} from "swr";
import { revalidatePath } from 'next/cache'
import SvgMijnMkg from "components/Svg/SvgMijnMkg";

export const FormInloggen = () => {
    const {mutate} = useSWRConfig()
    //const [errorMessage, dispatch] = useFormState(authenticate, undefined)
    const {tekst, isLoadingTekst} = useTekstClient();
    const {ingelogdeGebruiker} = useGebruiker()
    const {post} = useAxios()
    const [isVisible, setIsVisible] = useState(false);
    const [pending, setPending] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const router = useRouter()
    const [urlRedirect, setUrlRedirect] = useState()
    useEffect(() => {
        if (!ingelogdeGebruiker || urlRedirect) return
        router.push("/mijn-mkg/dashboard")
    }, [ingelogdeGebruiker]);

    useEffect(() => {
        if (!urlRedirect) return
        mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik`)
        mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/namensbedrijf`)
        mutate(`${process.env.NEXT_PUBLIC_BACKEND}/api/gebruiker/ik/rechten`)
        // revalidatePath(urlRedirect)
        router.push(urlRedirect)
        router.refresh()
    }, [urlRedirect])

    const toggleVisibility = () => setIsVisible(!isVisible);
    const handleClick = (event) => {
        event.preventDefault()
        setPending(true)
        post(`/inloggen`, { email: email, password: password }).then(response=>{
            if(response.status === 200){
                setUrlRedirect(response.data)
            } else {
                console.log(response.json())
            }
        }).catch((error) =>{
            console.log({error})
            setErrorMessage(tekst(`pagina:inloggen:inloggegevens zijn incorrect`))
        }).finally(() => {

            setPending(false)
        })
    }

    return <div >
        <div className="flex min-h-full flex-col justify-center px-6 pb-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <SvgMijnMkg width={60} classes="mx-auto h-16 w-auto"/>
                {/*<Image className="mx-auto h-16 w-auto"*/}
                {/*       src="/images/mijn-MKG.svg" alt="Mijn MKG" width={60} height={60}/>*/}
                <h2 className="mt-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">{tekst('algemeen:inloggen')}</h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
                <div>
                    <label htmlFor="email">{tekst('algemeen:email adres')}</label>
                    <div className="mt-2">
                        <Input variant={"bordered"} radius={"none"} id="email" name="email" type="email" onChange={(e) => setEmail(e.target.value)}
                               autoComplete="email" required/>
                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between">
                        <label htmlFor="password">{tekst('algemeen:wachtwoord')}</label>
                        <div className="text-sm">
                            <Link href={"/mijn-mkg/wachtwoord/herstel"}
                                  className={'font-semibold'}>{tekst('algemeen:wachtwoord vergeten?')}</Link>
                        </div>
                    </div>
                    <div className="mt-2">
                        <Input variant={"bordered"} radius={"none"} id="password" name="password"  onChange={(e) => setPassword(e.target.value)}
                               autoComplete="current-password"
                               endContent={
                                   <button
                                       className="focus:outline-none
                                       !bg-transparent
                                       !border-none
                                       shadow-none
                                       !w-4
                                       p-0
                                       d-flex
                                       justify-end
                                       mt-1"
                                       type="button" onClick={toggleVisibility}
                                       aria-label="toggle password visibility">
                                       {isVisible ? (
                                           <EyeSlashFilledIcon
                                               className="text-2xl text-default-400 pointer-events-none"/>
                                       ) : (
                                           <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none"/>
                                       )}
                                   </button>
                               }
                               type={isVisible ? "text" : "password"}
                               required/>
                    </div>
                </div>

                <div>
                    <button aria-disabled={pending} onClick={handleClick}
                            className={'w-100 btn btn-primary'}>{tekst('algemeen:inloggen')}</button>
                </div>

                <hr/>
                <p>
                    <Link
                        href={`${process.env.NEXT_PUBLIC_BACKEND}/saml2/mkg/login`}>{tekst('algemeen:Inloggen als behandelaar')}</Link>
                </p>

                {errorMessage && <Alert variant="warning">
                    <p>
                        {startsWith(errorMessage, '422:')
                            ? tekst('pagina:inloggen:inloggegevens zijn incorrect')
                            : errorMessage
                        }
                    </p>
                </Alert>}


            </div>
        </div>

    </div>
}